import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../components/context/AppContext";
import Link from "gatsby-link";
import { Helmet } from "react-helmet";

import { useMutation, useQuery } from "@apollo/client";
import { v4 } from "uuid";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";

import CHECKOUT_MUTATION from "../mutations/checkout";
import CLEAR_CART_MUTATION from "../mutations/clear-cart";
import GET_CART from "../queries/get-cart";
import YourCheckout from "../components/checkout/your-checkout";
import Purchase from "../components/data-layer/purchase";
//we need to clear all the coupons here also - get all the coupons with a query,
//then use the clear coupon mutation to remove them from cart.
import { getFormattedCart } from "../utils/functions";
import Layout from "../components/layout";

function capitalizeFirstLetter(string) {
  if (string === "firstName") {
    string = "first Name";
  } else if (string === "lastName") {
    string = "last Name";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

let mod = null;

const PaymentStatus = () => {
  const [cart, setCart] = useContext(AppContext);
  // Retrieve mod from local storage
  // Checkout or CreateOrder Mutation.
  const [checkout] = useMutation(CHECKOUT_MUTATION, {
    variables: {
      input: mod,
    },
    skip: true,
    onCompleted: () => {
      // Clear mod from local storage after retrieval (optional)
      // localStorage.removeItem("checkoutMod");
      console.warn("completed CHECKOUT_MUTATION");
      clearCart({
        variables: {
          input: {
            clientMutationId: v4(),
            all: true,
          },
        },
      });
      refetch();
    },
    onError: (error) => {
      if (error) {
        //console.log(error);
        //console.log(error.graphQLErrors[0]);
        alert(
          "An unknown error has occurred, our developers are working on a solution. Administrators will contact you shortly regarding your order. In the meantime, please clear your browser settings."
        );
        // Prepare the data to send to the custom WordPress REST endpoint
        mod.transactionId = new URLSearchParams(window.location.search)
          .get("payment_intent_client_secret")
          .split("_secret_")[0];
        const errorData = {
          errorMessage: error?.graphQLErrors[0]?.message || "Unknown error",
          debugMessage:
            error?.graphQLErrors[0]?.debugMessage || "Unknown error",
          input: mod,
          cart: cart,
          additionalInfo:
            "Ups, panel odbił zamówienie! Skontaktuj się z klientem.", // Add more context if needed
        };
        // console.log(errorData);
        async function customError() {
          try {
            // Send the data to the custom WordPress REST endpoint
            const response = await fetch(
              "https://admin.shroom4you.com/wp-json/custom/v1/error-log",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(errorData),
              }
            );

            if (!response.ok) {
              console.error("Failed to log the error on the WordPress site.");
            } else {
              console.log("Error successfully logged on the WordPress site.");
            }
          } catch (fetchError) {
            console.error("Fetch request failed:", fetchError);
          }
        }
        customError().then(() => {
          clearCart({
            variables: {
              input: {
                clientMutationId: v4(),
                all: true,
              },
            },
          });
          refetch();
          setTimeout(() => {
            window.location.href = "/checkout/";
          }, 2000);
          // setRequestError(error.graphQLErrors[0].message);
        });
      }
    },
  });

  // Get Cart Data.
  const { data, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      // console.warn( 'completed GET_CART', data );
      // Update cart in the localStorage.
      const updatedCart = getFormattedCart(data);
      localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart));
      // Update cart data in React Context.
      setCart(updatedCart);
    },
  });

  // Clear Cart Mutation.
  const [clearCart] = useMutation(CLEAR_CART_MUTATION, {
    onCompleted: () => {
      refetch();
      console.log("cleared cart");
    },
    skip: true,
    onError: (error) => {
      if (error) {
        console.log(error.graphQLErrors[0].message);
      }
    },
  });

  const stripe = useStripe();
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    mod = JSON.parse(localStorage.getItem("checkoutMod"));
    //console.log(mod)
    mod.currency = mod.currency.toUpperCase();
    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // Inspect the PaymentIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (paymentIntent.status) {
        case "succeeded":
          mod.isPaid = true;
          //console.log(mod)
          /*if(isBlik==='blik'){
              mod.paymentMethod = 'stripe_p24'
            }*/
          // Bootstrap row for billing and shipping modules
          // Function to generate HTML for an address object with 2 columns and 2 rows for each label and key
          function generateTableHTML(address, label) {
            var html = "";
            for (var key in address) {
              html += `
                <tr class="row m-0 p-0">
                  <td class="woo-next-checkout-total col-6">${capitalizeFirstLetter(
                    key
                  )}:</td>
                  <td class="woo-next-checkout-total col-6"><b>${
                    address[key]
                  }</b></td>
                </tr>
                `;
            }
            return html;
          }
          // Set HTML content for billing and shipping
          var billingBody = document.getElementById("billingBody");
          var shippingBody = document.getElementById("shippingBody");
          billingBody.innerHTML += generateTableHTML(mod.billing, "Billing");
          shippingBody.innerHTML += generateTableHTML(mod.shipping, "Shipping");

          setMessage("Success! Payment received.");
          mod.transactionId = new URLSearchParams(window.location.search)
            .get("payment_intent_client_secret")
            .split("_secret_")[0];
          const errorData = {
            input: mod,
            cart: cart,
            additionalInfo: "Nowe zamówienie.", // Add more context if needed
          };
          // console.log(errorData);
          async function customOrder() {
            try {
              // Send the data to the custom WordPress REST endpoint
              const response = await fetch(
                "https://admin.shroom4you.com/wp-json/custom/v1/order-log",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(errorData),
                }
              );

              if (!response.ok) {
                console.error("Failed to log the error on the WordPress site.");
              } else {
                console.log("Order successfully logged on the WordPress site.");
              }
            } catch (fetchError) {
              console.error("Fetch request failed:", fetchError);
            }
          }
          customOrder().then(() => {
            Purchase({ mod, cart });
            checkout();
          });

          break;
        case "processing":
          mod.isPaid = false;
          //console.log(mod)
          setMessage(
            "Payment processing. We'll update you when payment is received."
          );
          checkout();
          break;
        case "requires_payment_method":
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          //console.log(mod)
          setMessage("Payment failed. Please try another payment method.");
          setTimeout(() => {
            window.location.href = "/checkout/";
          }, 2000);
          break;
        default:
          mod.isPaid = false;
          setMessage("Something went wrong.");
          checkout();
          break;
      }
    });
  }, [stripe]);

  return message;
  /*return null*/
};

const OrderSuccess = () => {
  const [cart, setCart] = useContext(AppContext);
  const [tempCart, setTempCart] = useState(null);

  const stripePromise = loadStripe(process.env.PUBLIC_STRIPE_PUBLISHABLE_KEY);

  let mod = null;

  useEffect(() => {
    mod = JSON.parse(localStorage.getItem("checkoutMod"));
    if (cart !== null) {
      setTempCart(cart);
    }
    //mod.currency = mod.currency.toUpperCase();
    // console.log(mod)
  }, [cart, mod]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout>
        <div
          className="checkout container has-text-centered my-5"
          style={{ textAlign: "center" }}
        >
          <br />
          <h1 className="mt-2 display-4 spec-h">
            Your order
            <br /> has been received.
          </h1>
          <br />
          <h2 className="mt-2 display-5 spec-h">Thank You!</h2>
          {/*<br />
      <sub>Order status:</sub>
      <br />*/}
          <Elements stripe={stripePromise}>
            <PaymentStatus />
          </Elements>
          <br />
          <div id="shroom-order" className="container mt-0 p-5 text-left">
            <h3>Your shipping and billing details:</h3>
            <p>
              The data you provided during the process. You can find the history
              of your orders and data in the{" "}
              <Link to="/my-account/">my account</Link> section.
            </p>
            <div>
              <table
                border="0"
                className="row columns m-0"
                style={{ backgroundColor: "#ffffff", maxWidth: "100%" }}
                id="tableBody"
              >
                <tbody
                  className="table table-hover col-6 m-0 p-1 text-left"
                  style={{
                    backgroundColor: "#fcfaf6",
                    borderTop: "0",
                    display: "table-column",
                    width: "100%",
                  }}
                  id="billingBody"
                >
                  <tr
                    className="woo-next-cart-head-container row m-0"
                    style={{ backgroundColor: "#f7adb6", maxWidth: "100%" }}
                  >
                    <th className="col-12 text-center woo-next-cart-heading-el">
                      Billing
                    </th>
                  </tr>
                </tbody>
                <tbody
                  className="table table-hover col-6 m-0 p-1 text-left"
                  style={{
                    backgroundColor: "#fcfaf6",
                    borderTop: "0",
                    display: "table-column",
                    width: "100%",
                  }}
                  id="shippingBody"
                >
                  <tr
                    className="woo-next-cart-head-container row m-0"
                    style={{ backgroundColor: "#f7adb6", maxWidth: "100%" }}
                  >
                    <th className="col-12 text-center woo-next-cart-heading-el">
                      Shipping
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <h3>Order details:</h3>
            <p>The full details of your order below.</p>
            <div className="p-1" style={{ backgroundColor: "#fcfaf6" }}>
              <YourCheckout
                currency={mod?.currency ? mod.currency : "pln"}
                tempCart={tempCart}
              />
            </div>
          </div>
          <br />
          <br />
          <Link className="m-1" to="/">
            <b style={{ fontSize: "1.2em" }} className="btn btn-primary">
              Go back
            </b>
          </Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="m-1" to="/my-account/">
            <b style={{ fontSize: "1.2em" }} className="btn btn-primary">
              My account
            </b>
          </Link>
          <br />
          <br />
          <br />
          <p>Got any questions about your order?</p>
          <Link to="/contact/">
            <b style={{ fontSize: "1.2em" }} className="btn btn-primary">
              Contact us
            </b>
          </Link>
          <br />
          <br />
          <br />
          <h3 className="mt-2 display-5 spec-h">
            Thank You for shopping with us. <br />
            Here is a bonus of -5% on your next order<code>shroom_bonus</code>.
          </h3>
          <br />
        </div>
      </Layout>
    </>
  );
};

export default OrderSuccess;
